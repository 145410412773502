var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"terms"},[_c('h1',{staticClass:"title"},[_vm._v("关于发货")]),_c('p',{staticClass:"titleInfo"},[_vm._v("Shipping")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('h1',{staticClass:"title"},[_vm._v("关于派送")]),_c('p',{staticClass:"titleInfo"},[_vm._v("Delivery")]),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_c('h1',{staticClass:"title"},[_vm._v("关于延迟交货")]),_c('p',{staticClass:"titleInfo"},[_vm._v("Late Deliveries")]),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_c('div',{staticClass:"agreeBox",on:{"click":_vm.gotoReturn}},[_c('div',{staticClass:"agree"},[_vm._v(_vm._s(_vm.$t.meta.returnRules))])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" （一）卖家须在买家付款后，按照约定在承诺的时间内发货；"),_c('br'),_vm._v(" The seller shall deliver goods within the promised time as agreed upon after payment by the buyer; ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" （二）除特殊情况下，卖家须在买家付款后48小时内发货。"),_c('br'),_vm._v(" Except under special circumstances, the seller shall deliver the goods within 48 hours after the buyer's payment. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 1. 卖家若加入VANCHEEN网活动的，以活动要求的发货时间为准；"),_c('br'),_vm._v(" If the seller joins the VANCHEEN activities, the delivery time required by the activities shall prevail. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 2. 国庆节假日（具体时间以法定的国庆节假日安排为准）期间付款的订单，卖家须在买家付款后的72小时内发货；"),_c('br'),_vm._v(" For orders paid during the National Day holiday (the specific time is subject to the statutory National Day holiday arrangement), the seller shall deliver the goods within 72 hours after the buyer's payment; ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 3. 春节前后（具体时间以VANCHEEN平台通知周期为准）付款的订单，发货时间以特殊通知为准。"),_c('br'),_vm._v(" For orders paid before or after the Spring Festival (the specific time shall be subject to the notice period of VANCHEEN platform), the delivery time shall be subject to the special notice. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 除上述特殊情况下，七天内，卖家必须把货物送达至VANCHEEN平台集运仓。若期限已到但货未入仓，则订单自动取消及平台自动向买家退款，由卖家承担此次订单取消及退货造成的往返快递运输成本；若货物如期入仓，VANCHEEN平台则在七天内(不可抗力因素除外)为买家客户对该票订单进行货物集运，再统一发往指定国家及地址。"),_c('br'),_vm._v(" Except under the above special circumstances, the Seller must deliver the goods to the warehouse of VANCHEEN Platform within 7 days. If the deadline has expired but the goods are not delivery to Vancheen’s warehouse, the order will be automatically cancelled and the platform will automatically refund the buyer, and the seller will bear the round-trip express transportation costs caused by the order cancellation and return. If the goods are delivery to the warehouse as scheduled, VANCHEEN platform will collect and ship the goods for the buyer within seven days (excluding force majeure factors), and then uniformly send the goods to the specified country and address. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 大多数包裹准时到达。在极少数情况下，跟踪可能会显示为“已送达”，但如果您的包裹尚未到达。"),_c('br'),_vm._v(" Most packages arrive on time. On very rare occasions, the tracking may show as \"delivered\" but it looks like your package has not arrived. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 注意：一些承运人可能会在晚上 10 点之前送货。"),_c('br'),_vm._v(" Note: Some carriers may deliver until 10 pm. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 如果您的追踪信息显示您的包裹已送达，但您找不到："),_c('br'),_vm._v(" If your tracking information shows that your package was delivered, but you can't find it: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 1. 验证您的订单中的送货地址。"),_c('br'),_vm._v(" Verify the shipping address in Your Orders. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 2.寻找试图交付的通知。"),_c('br'),_vm._v(" Look for a notice of attempted delivery. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 3.环顾您的包裹的交付地点。"),_c('br'),_vm._v(" Look around the delivery location for your package. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 4.查看其他人是否接受了交付，除非您对此有健康或安全方面的担忧。"),_c('br'),_vm._v(" See if someone else accepted the delivery, unless you have health or safety concerns. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 5.如果您仍未收到包裹，请在预计送达后 10 天内联系我们。我们将尽我们所能提供帮助。"),_c('br'),_vm._v(" If you still haven't received your package, contact us, we'll do everything we can to help. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 大多数包裹准时到达。订单有时会在预计交货日期之后出现。"),_c('br'),_vm._v(" Most packages arrive on time. On very rare occasions, the tracking may show as \"delivered\" but it looks like your package has not arrived. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 延迟交货的可能原因包括："),_c('br'),_vm._v(" Possible reasons for late delivery include the following: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 1.地址不正确"),_c('br'),_vm._v(" Incorrect address ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 2.缺少公寓、建筑物、楼层或单元号"),_c('br'),_vm._v(" Missing apartment, building, floor, or unit number"),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 3.恶劣的天气条件"),_c('br'),_vm._v(" Severe weather conditions ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 4.国际海关手续"),_c('br'),_vm._v(" International customs procedures ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 建议采取的措施是："),_c('br'),_vm._v(" The recommended actions are: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 1.跟踪您的包裹并在您的订单中确认预计交货日期。"),_c('br'),_vm._v(" Track your package and confirm estimated delivery date in Your Orders. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 2.在您的订单中确认您的送货地址。为避免递送问题，请及时更新您的地址信息并将递送说明添加到您的地址。"),_c('br'),_vm._v(" Confirm your shipping address in Your Orders. To avoid delivery problems, keep your address information up to date and add delivery instructions to Your Addresses. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 3.检查您的订单中的付款处理。"),_c('br'),_vm._v(" Check payment processing in Your Orders. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"s1"},[_vm._v(" 4.等待48 小时以防意外延误。"),_c('br'),_vm._v(" Wait 48 hours to allow for unexpected delays. ")])
}]

export { render, staticRenderFns }