<template>
  <div class="terms">
    <h1 class="title">关于发货</h1>
    <p class="titleInfo">Shipping</p>

    <p class="s1">
      （一）卖家须在买家付款后，按照约定在承诺的时间内发货；<br />
      The seller shall deliver goods within the promised time as agreed upon
      after payment by the buyer;
    </p>

    <p class="s1">
      （二）除特殊情况下，卖家须在买家付款后48小时内发货。<br />
      Except under special circumstances, the seller shall deliver the goods
      within 48 hours after the buyer's payment.
    </p>

    <p class="s1">
      1. 卖家若加入VANCHEEN网活动的，以活动要求的发货时间为准；<br />
      If the seller joins the VANCHEEN activities, the delivery time required by
      the activities shall prevail.
    </p>

    <p class="s1">
      2.
      国庆节假日（具体时间以法定的国庆节假日安排为准）期间付款的订单，卖家须在买家付款后的72小时内发货；<br />
      For orders paid during the National Day holiday (the specific time is
      subject to the statutory National Day holiday arrangement), the seller
      shall deliver the goods within 72 hours after the buyer's payment;
    </p>

    <p class="s1">
      3.
      春节前后（具体时间以VANCHEEN平台通知周期为准）付款的订单，发货时间以特殊通知为准。<br />
      For orders paid before or after the Spring Festival (the specific time
      shall be subject to the notice period of VANCHEEN platform), the delivery
      time shall be subject to the special notice.
    </p>

    <p class="s1">
      除上述特殊情况下，七天内，卖家必须把货物送达至VANCHEEN平台集运仓。若期限已到但货未入仓，则订单自动取消及平台自动向买家退款，由卖家承担此次订单取消及退货造成的往返快递运输成本；若货物如期入仓，VANCHEEN平台则在七天内(不可抗力因素除外)为买家客户对该票订单进行货物集运，再统一发往指定国家及地址。<br />
      Except under the above special circumstances, the Seller must deliver the
      goods to the warehouse of VANCHEEN Platform within 7 days. If the deadline
      has expired but the goods are not delivery to Vancheen’s warehouse, the
      order will be automatically cancelled and the platform will automatically
      refund the buyer, and the seller will bear the round-trip express
      transportation costs caused by the order cancellation and return. If the
      goods are delivery to the warehouse as scheduled, VANCHEEN platform will
      collect and ship the goods for the buyer within seven days (excluding
      force majeure factors), and then uniformly send the goods to the specified
      country and address.
    </p>

    <h1 class="title">关于派送</h1>
    <p class="titleInfo">Delivery</p>
    <p class="s1">
      大多数包裹准时到达。在极少数情况下，跟踪可能会显示为“已送达”，但如果您的包裹尚未到达。<br />
      Most packages arrive on time. On very rare occasions, the tracking may
      show as "delivered" but it looks like your package has not arrived.
    </p>
    <p class="s1">
      注意：一些承运人可能会在晚上 10 点之前送货。<br />
      Note: Some carriers may deliver until 10 pm.
    </p>
    <p class="s1">
      如果您的追踪信息显示您的包裹已送达，但您找不到：<br />
      If your tracking information shows that your package was delivered, but
      you can't find it:
    </p>

    <p class="s1">
      1. 验证您的订单中的送货地址。<br />
      Verify the shipping address in Your Orders.
    </p>

    <p class="s1">
      2.寻找试图交付的通知。<br />
      Look for a notice of attempted delivery.
    </p>

    <p class="s1">
      3.环顾您的包裹的交付地点。<br />
      Look around the delivery location for your package.
    </p>

    <p class="s1">
      4.查看其他人是否接受了交付，除非您对此有健康或安全方面的担忧。<br />
      See if someone else accepted the delivery, unless you have health or
      safety concerns.
    </p>

    <p class="s1">
      5.如果您仍未收到包裹，请在预计送达后 10
      天内联系我们。我们将尽我们所能提供帮助。<br />
      If you still haven't received your package, contact us, we'll do
      everything we can to help.
    </p>

    <h1 class="title">关于延迟交货</h1>
    <p class="titleInfo">Late Deliveries</p>
    <p class="s1">
      大多数包裹准时到达。订单有时会在预计交货日期之后出现。<br />
      Most packages arrive on time. On very rare occasions, the tracking may
      show as "delivered" but it looks like your package has not arrived.
    </p>

    <p class="s1">
      延迟交货的可能原因包括：<br />
      Possible reasons for late delivery include the following:
    </p>

    <p class="s1">
      1.地址不正确<br />
      Incorrect address
    </p>

    <p class="s1">
      2.缺少公寓、建筑物、楼层或单元号<br />
      Missing apartment, building, floor, or unit number<br />
    </p>

    <p class="s1">
      3.恶劣的天气条件<br />
      Severe weather conditions
    </p>

    <p class="s1">
      4.国际海关手续<br />
      International customs procedures
    </p>

    <p class="s1">
      建议采取的措施是：<br />
      The recommended actions are:
    </p>

    <p class="s1">
      1.跟踪您的包裹并在您的订单中确认预计交货日期。<br />
      Track your package and confirm estimated delivery date in Your Orders.
    </p>

    <p class="s1">
      2.在您的订单中确认您的送货地址。为避免递送问题，请及时更新您的地址信息并将递送说明添加到您的地址。<br />
      Confirm your shipping address in Your Orders. To avoid delivery problems,
      keep your address information up to date and add delivery instructions to
      Your Addresses.
    </p>

    <p class="s1">
      3.检查您的订单中的付款处理。<br />
      Check payment processing in Your Orders.
    </p>

    <p class="s1">
      4.等待48 小时以防意外延误。<br />
      Wait 48 hours to allow for unexpected delays.
    </p>

    <div class="agreeBox" @click="gotoReturn">
      <div class="agree">{{ $t.meta.returnRules }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ShippingRules",
  data() {
    return {};
  },
  created() {
    const { headerHide } = this.$route.query;
    if (headerHide) {
      this.headerHide = JSON.parse(headerHide);
      if (this.headerHide) {
        document.getElementsByClassName("van-nav-bar")[0].style.display =
          "none";
      }
    }
  },
  methods: {
    gotoReturn() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "goodsTerms/ReturnRules",
        name: "ReturnRules",
        query: [],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.terms {
  width: 90%;
  margin: 0 auto;
  * {
    font-size: 11px;
  }
  .title {
    width: 100%;
    text-align: center;
    font-size: 13px;
    margin-top: 5%;
  }
  .titleInfo {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }
  .info {
    width: 100%;
    text-align: center;
    margin: 25px 0;
  }
  .s1 {
    text-align: left;
    margin: 25px 0;
  }
  .sTitle {
    text-align: left;
    margin: 25px 0;
  }
}
.agreeBox {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: white;
  padding: 3% 0;
  text-align: center;
  border-top: 1px solid #e5e5e5;
  .agree {
    width: 80%;
    margin: auto;
    border-radius: 3rem;
    padding: 0.8rem 0;
    text-align: center;
    background: linear-gradient(left, #71d283, #01aaa3);
    color: #ffffff;
    font-size: 1rem;
    line-height: 1;
  }
}
</style>
